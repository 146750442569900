<template>
  <div class="pt-6 pb-20 lg:pb-24">
    <h3 v-if="title"
        class="max-w-3xl text-2xl lg:text-4xl leading-[1.2] text-white tracking-[-1px] mb-6 lg:mb-14"
    >
      {{ title }}
    </h3>
    <NuxtLink v-for="(job, index) in jobsSortedByPriority"
              :key="index"
              class="flex flex-col items-start justify-between pt-6 pb-6 transition border-b border-gray-400 lg:px-4 lg:hover:bg-gray-500 lg:flex-row lg:pt-8 lg:items-center group"
              :href="job.uri"
    >
      <div class="">
        <div class="text-xl text-gray-100 transition lg:text-2xl lg:text-gray-200 lg:group-hover:text-white">
          {{ job.title }}
        </div>
        <div class="">
          <span v-for="(location_, i) in job.locations"
                :key="location_.key"
                class="mt-1 text-gray-250"
          >{{ i
            > 0 ? ' • ' : '' }}{{ location_.label }}
          </span>
        </div>
      </div>

      <div class="flex items-start mt-4 space-x-2 lg:space-y-0 lg:ml-auto lg:mt-0">
        <div v-if="job.category"
             class="px-4 py-1 text-sm bg-gray-200 border border-gray-200 rounded-full"
        >
          {{ job.category }}
        </div>
        <div v-if="job.type"
             class="px-4 py-1 text-sm text-gray-200 border border-gray-200 rounded-full"
        >
          {{ job.type }}
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { JobType } from '~~/types/jobs'

interface TableProps {
  title?: string
  jobs: JobType[]
}

const props = withDefaults(defineProps<TableProps>(), {
  title: ''
})

const jobsSortedByPriority = computed(() => {
  const jobs = [...props.jobs]

  jobs.forEach(job => {
    if (!job.priority) job.priority = 3
  })

  return jobs.sort((a, b) => a.priority - b.priority)
})
</script>
